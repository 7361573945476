<template>
  <div id="app">
    <!-- 顶部导航栏 -->
    <el-container :key="$route.fullPath">
      <el-header :class="{ scrolled: isScrolled }">
        <el-menu
          class="el-menu-demo"
          mode="horizontal"
          :ellipsis="false"
          @select="handleSelect"
          style="height: 80px;"
        >
          <!-- 公司logo -->
          <div class="logo-container">
            <el-menu-item index="0">
              <router-link to="/Home">
                <img
                  class="logo"
                  :src="require('@/media/logo.pic.jpg')"
                  alt="公司logo"
                />
              </router-link>
            </el-menu-item>
          </div>

          <!-- 移动端菜单按钮 -->
          <div class="mobile-menu-button" @click="toggleMobileMenu">
            <el-icon><Menu /></el-icon>
          </div>

          <!-- 导航菜单 -->
          <div class="nav-menu" :class="{ 'mobile-menu-active': isMobileMenuOpen }">
            <el-menu-item index="1">
              <el-link :underline="false" @click="$router.push('/Home')">首页</el-link>
            </el-menu-item>
            <el-menu-item index="2">
              <el-link :underline="false" @click="$router.push('/Product')">产品</el-link>
            </el-menu-item>
            <el-menu-item index="3">
              <el-link :underline="false" @click="$router.push('/TechSupport')">支持</el-link>
            </el-menu-item>
            <el-menu-item index="4">
              <el-link :underline="false" @click="$router.push('/Download')">下载</el-link>
            </el-menu-item>
            <el-menu-item index="5">
              <el-link :underline="false" @click="$router.push('/Subscribe')">订阅服务</el-link>
            </el-menu-item>

            <!-- 用户操作部分 -->
            <div class="user-actions">
              <el-menu-item index="6" v-if="isAuthenticated">
                <el-dropdown>
                  <el-avatar :size="40" :src="require('@/media/userlogo.jpg')" />
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item @click="goToUserPage">个人中心</el-dropdown-item>
                      <el-dropdown-item @click="handleLogout">退出登录</el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </el-menu-item>
              <el-menu-item v-else>
                <router-link to="/Login">登录</router-link>
              </el-menu-item>
            </div>
          </div>
        </el-menu>
      </el-header>
      <el-main>
        <router-view v-slot="{ Component }">
          <component 
            :is="Component" 
            @hook:mounted="handleRouteChange"
          />
        </router-view>
      </el-main>
      <!-- 底部slogan -->
      <h2 class="slogan">
        良工必有博峤，秉承极致、快、服务的理念，
        推出面向全球的机器视觉相机、传输及算法模块！
      </h2>
       <!-- 底部导航栏 -->
      <el-footer class="footer-nav">
      <div class="container">
        <el-row>
          <el-col :span="6" :xs="12" :sm="6" :md="6" class="footer-col">
            <img src="@/media/telephone.jpg" alt="电话图标" class="resized-image">
            <p>总机电话</p>
            <p>15914152976</p>
          </el-col>
          <el-col :span="6" :xs="12" :sm="6" :md="6" class="footer-col">
            <img src="@/media/mail.jpg" alt="邮箱图标" class="resized-image">
            <p>联系邮箱 </p>
            <p>mahanting@zhbojay.com</p>
          </el-col>
          <el-col :span="6" :xs="12" :sm="6" :md="6" class="footer-col">
            <img src="@/media/maintenance.jpg" alt="售后图标" class="resized-image">
            <p>客服热线</p>
            <p>17882351005</p>
          </el-col>
          <el-col :span="6" :xs="12" :sm="6" :md="6" class="footer-col">
            <img src="@/media/automation.jpg" alt="技术图标" class="resized-image">
            <p>技术邮箱</p>
            <p>xiaohe@zhbojay.com</p>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="6" :xs="24" :sm="12" :md="6" class="footer-col">
            <div class="footer_logo">
              <img style="width: 80px" :src="require('@/media/logoWhite.png')" alt="公司logo" />
            </div>
          </el-col>
          <el-col :span="6" :xs="24" :sm="12" :md="6" class="footer-col">
            <ul>
              <li><router-link to="/Home" @click.native="scrollToTop">首页</router-link></li>
              <li><router-link to="/Product" @click.native="scrollToTop">产品</router-link></li>
              <li><router-link to="/Login" @click.native="scrollToTop">登录</router-link></li>
              <li><router-link to="/Login" @click.native="scrollToTop">注册</router-link></li>
            </ul>
          </el-col>
          <el-col :span="6" :xs="24" :sm="12" :md="6" class="footer-col">
            <ul>
              <li><router-link to="/TechSupport">支持</router-link></li>
              <li><router-link to="/Download">下载</router-link></li>
              <li><router-link to="/Subscribe">订阅服务</router-link></li>
              <li><router-link to="/MoreProduct">更多产品</router-link></li>
            </ul>
          </el-col>
          <el-col :span="6" :xs="24" :sm="12" :md="6" class="footer-col">
          <h4>扫码关注我们的动态</h4>
          <el-row class="contact-icons" >
            <el-col :span="6" :xs="8" :sm="6" :md="6">
              <el-popover placement="top" trigger="click">
                <template #reference>
                  <img src="@/media/wx.png" class="icon-image" alt="Icon 1">
                </template>
                <img src="@/media/QRcodeWX.png" alt="QR Code 1" style="width: 120px;">
              </el-popover>
            </el-col>

            <el-col :span="6" :xs="8" :sm="6" :md="6">
              <el-popover placement="top" trigger="click">
                <template #reference>
                  <img src="@/media/dy.png" class="icon-image" alt="Icon 2">
                </template>
                <img src="@/media/QRcodeWX.png" alt="QR Code 2" style="width: 120px;">
              </el-popover>
            </el-col>

            <el-col :span="6" :xs="8" :sm="6" :md="6">
              <el-popover placement="top" trigger="click">
                <template #reference>
                  <img src="@/media/wxsph.png" class="icon-image" alt="Icon 3">
                </template>
                <img src="@/media/QRcodeWX.png" alt="QR Code 3" style="width: 120px;">
              </el-popover>
            </el-col>

            <!-- 根据需要添加更多图标 -->
          </el-row>

          </el-col>
        </el-row>

        <el-row>
          <el-col :span="24" class="footer-col">
            <p class="copyright-text">
              Copyright © 2024 All Rights Reserved 深圳市博峤技术有限公司·版权所有 粤ICP备2024297890号
            </p>
          </el-col>
        </el-row>
      </div>
    </el-footer>
    <!-- 底部导航栏 -->
    </el-container>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue';
import { useRouter } from 'vue-router';
import { mapGetters, mapActions } from 'vuex';
import './views/total.css';
import { Menu } from '@element-plus/icons-vue'  // 导入菜单图标

import {
  ElContainer,
  ElHeader,
  ElMain,
  ElFooter,
  ElMenu,
  ElMenuItem,
  ElSubMenu,
  ElRow,
  ElCol,
  ElAvatar
} from 'element-plus';

export default {
  mounted() {
    document.title = '博峤技术工业相机';
  },

  name: 'Navbar', // 将 'name' 移到了顶部
  components: {
    ElContainer,
    ElHeader,
    ElMain,
    ElFooter,
    ElMenu,
    ElMenuItem,
    ElSubMenu,
    ElRow,
    ElCol,
    ElAvatar,
    Menu,
  },
  setup() {
    const activeIndex = ref('1');
    const isScrolled = ref(false); // 新增，用于跟踪滚动状态
    const isMobileMenuOpen = ref(false);

    const handleSelect = (key, keyPath) => {
      console.log(`selected ${key} from ${keyPath}`);
    };

    const router = useRouter();
    const goToUserPage = () => {
      console.log(router);
      router.push('/User');
    };

    // 滚动事件处理函数
    const handleScroll = () => {
      isScrolled.value = window.scrollY > 10; // 当滚动超过10px时，认为页面已滚动
    };

    // 添加路由监听，根据当前路由更新activeIndex
    const updateActiveIndex = (route) => {
      const path = route.path;
      // 根据路径设置对应的activeIndex
      switch (true) {
        case path === '/Home':
          activeIndex.value = '1';
          break;
        case path.includes('/Product'):
          activeIndex.value = '2';
          break;
        case path.includes('/About'):
          activeIndex.value = '3';
          break;
        case path.includes('/Subscribe'):
          activeIndex.value = '4';
          break;
        case path.includes('/Download'):
          activeIndex.value = '5';
          break;
        case path === '/User':
          activeIndex.value = '6';
          break;
        default:
          activeIndex.value = '1';
      }
    };

    // 监听路由变化
    onMounted(() => {
      updateActiveIndex(router.currentRoute.value); // 初始化
      router.afterEach((to) => {
        updateActiveIndex(to);
      });
    });

    onUnmounted(() => {
      window.removeEventListener('scroll', handleScroll);
    });

    return {
      activeIndex,
      handleSelect,
      goToUserPage,
      isScrolled, // 返回到模板中，以便绑定类
      isMobileMenuOpen,
    };
  },
  computed: {
    ...mapGetters(['isAuthenticated'])
  },
  methods: {
    ...mapActions(['checkAuthentication', 'logout']),
    handleLogout() {
      this.logout();
      this.$router.push('/login'); // 跳转到登录页
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // 平滑滚动
      });
    },
    toggleMobileMenu() {
      this.isMobileMenuOpen = !this.isMobileMenuOpen;
    },
    handleRouteChange() {
      // 在组件挂载时滚动到顶部
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    },
    closeMenu() {
      // 只在移动端模式下关闭菜单
      if (window.innerWidth <= 768) {
        this.isMobileMenuOpen = false;
      }
    },
    handleSelect(key, keyPath) {
      console.log(`selected ${key} from ${keyPath}`);
      this.closeMenu(); // 在选择菜单项后关闭菜单
    },
    toggleMobileMenu() {
      this.isMobileMenuOpen = !this.isMobileMenuOpen;
    }
  },
  created() {
    this.checkAuthentication(); // 检查用户是否已登录
  },
  watch: {
    // 监听路由变化
    '$route'() {
      // 路由变化时滚动到顶部
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      this.closeMenu();
    }
  }
};
</script>


<style scoped>
/* 顶部导航栏基础样式 */
.el-header {
  position: fixed;
  z-index: 1000;
  height: 80px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.95);
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;
}

/* 主菜单样式 */
.el-menu-item, .el-sub-menu__title {
  height: 100px;
  line-height: 100px;
  font-size: 16px;
  font-weight: 500;
  color: #333;
  transition: all 0.3s;
}

.el-menu-demo {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  font-size: 16px;
  border-bottom: none; /* 确保没有底部边框 */
  background-color: transparent !important;
}

/* Logo的调整 */
.logo-container {
  display: flex;
  align-items: center;
}

.logo {
  width: 60px; /* 默认Logo大小 */
  height: auto;
}

/* 弹性填充空间 */
.flex-grow {
  flex-grow: 1;
}

/* 头像和登出按钮的调整 */
.el-avatar,
.el-button {
  margin-left: 10px;
}

/* 响应式设计的媒体查询 */
@media (max-width: 768px) {
  .el-header {
    flex-direction: column;
    height: auto;
    position: relative; /* 小屏幕上取消固定定位 */
  }

  .el-menu-demo {
    flex-direction: column;
    align-items: center; /* 居中对齐菜单项 */
    border-bottom: none; /* 确保在小屏幕时没有底部边框 */
    width: 100%;
  }

  .logo {
    width: 40px; /* 移动设备上较小的Logo大小 */
  }

  .el-menu-item,
  .el-sub-menu {
    width: 100%;
    padding: 10px 20px;
  }

  .el-avatar {
    width: 30px;
    height: 30px;
  }

  .el-button {
    padding: 5px 10px; /* 较小的按钮尺寸 */
  }
}

/* 底部导航栏 */
.footer-nav {
  background-color: #5c85ad; /* 确保背景颜色与文字背景颜色一致 */
  color: #fff;
  text-align: center;
  padding: 40px 0;
}

.footer-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
  background-color: #5c85ad; /* 确保背景颜色与文字背景颜色一致 */
}

.footer-col h2 {
  color: #fff;
  margin-bottom: 20px;
}

.footer-col ul {
  list-style: none;
  padding: 0;
}

.footer-col ul li {
  margin-bottom: 10px;
  text-align: center;
}

.footer-col ul li a {
  color: #fff;
  text-decoration: none;
  background-color: #5c85ad; /* 确保背景颜色与文字背景颜色一致 */
}

.footer-col ul li a:hover {
  color: #409EFF;
}

.footer_logo img {
  max-width: 150px;
  margin-bottom: 20px;
}

.contact-icons {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  gap: 20px;
  flex-wrap: wrap; /* 允许元素换行 */
}

.icon-image {
  width: 40px;
  height: 40px;
  cursor: pointer;
  margin: 5px;
  background-color: #4397c8;
  border-radius: 50%;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  object-fit: cover; /* 确保图标不被拉伸 */
}

.slogan {
  font-size: 30px; /* 字体大小，可根据需要调整 */
  font-weight: bold; /* 字体粗细 */
  color: #333; /* 文字颜色 */
  line-height: 1.5; /* 行高 */
  background: linear-gradient(to right, #1970D2, #9CD7FF); /* 渐变背景色 */
  -webkit-background-clip: text;
  color: transparent;
  animation: fadeIn 3s ease-in-out;
  text-align: center; /* 文字水平居中 */
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* 照片大小 */
.resized-image {
  width: 11%; /* 调整为所需的宽度，比如 100%，50px，或者 300px */
  height: auto; /* 确保图片保持宽高比 */
}

/* 菜单项的CSS样式 */
.el-menu-item a {
  color: inherit;
  text-decoration: none;
}

.el-menu-item.is-active {
  color: inherit;
}

.el-menu-item a:visited {
  color: inherit;
}

.el-menu-item a:hover {
  color: #ffffff; /* Element Plus 主色 */
}

.el-menu-item a:active {
  color: inherit;
}

/* 主菜单容器样式 */
.el-menu-demo {
  border: none !important;
  background-color: transparent;
  position: relative; /* 添加相对定位 */
}

/* 导航栏响应式优化 */
@media (max-width: 992px) {
  .el-header {
    height: auto;
    padding: 0;
    position: relative; /* 改为相对定位 */
  }

  .el-menu-demo {
    flex-direction: column;
    padding: 10px 0;
    height: auto !important; /* 取消固定高度 */
  }

  .logo-container {
    width: 100%;
    justify-content: center;
    padding: 10px 0;
  }

  .flex-grow {
    display: none;
  }

  /* 导航菜单项样式 */
  .el-menu-item,
  .el-sub-menu__title {
    height: 50px !important;
    line-height: 50px !important;
    justify-content: center;
    text-align: center;
    padding: 0 !important;
    width: 100%; /* 确保宽度填满 */
  }

  /* 用户操作区域样式 */
  .user-actions {
    display: flex;
    justify-content: center;
    padding: 10px 0;
    gap: 15px;
    border-top: 1px solid var(--el-border-color-light);
    width: 100%; /* 确保宽度填满 */
  }
}

/* 平板和中等屏幕优化 */
@media (max-width: 768px) {
  /* 用户操作区域优化 */
  .user-actions {
    padding: 5px 0;
    background-color: #fff;
    border-top: 1px solid #f0f0f0;
  }

  .user-actions .el-menu-item {
    border: none;
    padding: 0 10px !important;
  }

  /* 复链接样式 */
  .el-menu-item a {
    display: block;
    width: 100%;
    height: 100%;
    line-height: 50px;
  }

  /* 调整主内容区域 */
  .el-main {
    padding-top: 0;
  }

  /* 修复滚动问题 */
  .el-container {
    height: auto;
    min-height: 100vh;
  }
}

/* 确保内容不会被导航栏遮挡 */
.el-main {
  margin-top: 0;
  padding-top: 80px;
}

/* 修复移动端菜单层级和过渡 */
.el-menu,
.el-menu-item,
.el-sub-menu,
.user-actions {
  transition: all 0.3s ease-in-out;
}

/* 修复移动端点击状态 */
.el-menu-item:active,
.el-menu-item:focus {
  background-color: #f5f7fa;
}

/* 修复移动端hover效果 */
@media (hover: hover) {
  .el-menu-item:hover {
    background-color: #f5f7fa;
  }
}

/* 导航菜单基础样式 */
.el-menu-demo {
  border: none !important;
  background-color: transparent;
}

/* 菜单项样式 */
.el-menu-item {
  height: 100px;
  line-height: 100px;
  border-bottom: none !important;
  transition: all 0.3s;
  cursor: pointer;
  color: #333;
  font-weight: 500;
}

/* 菜单项hover效果 */
.el-menu-item:hover {
  background-color: rgba(255, 255, 255, 0.2) !important;
}

/* 滚动后的样式 */
.scrolled .el-menu-item {
  height: 80px;
  line-height: 80px;
}

/* 移除所有下划线 */
:deep(.el-menu-item),
:deep(.el-menu--horizontal > .el-menu-item) {
  border-bottom: none !important;
}

/* 激活状态样式 */
:deep(.el-menu--horizontal > .el-menu-item.is-active) {
  border-bottom: none !important;
  color: var(--el-menu-active-color);
}

/* Logo样式 */
.logo-container {
  height: 50%;
  display: flex;
  align-items: center;
}

.logo {
  height: 60px;
  width: auto;
  transition: all 0.3s;
}

.scrolled .logo {
  height: 45px;
}

/* 移动端菜单按钮样式 */
.mobile-menu-button {
  display: none;
  padding: 10px;
  cursor: pointer;
  font-size: 24px;
}

/* 导航菜单基础样式 */
.nav-menu {
  display: flex;
  flex: 1;
  align-items: center;
}

/* 响应式设计 */
@media (max-width: 768px) {
  .mobile-menu-button {
    display: block;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
  }

  .nav-menu {
    display: none;
    position: absolute;
    top: 80px;
    left: 0;
    width: 100%;
    background-color: white;
    flex-direction: column;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  }

  .nav-menu.mobile-menu-active {
    display: flex;
  }

  .nav-menu .el-menu-item {
    width: 100%;
    text-align: center;
    padding: 15px 0;
  }

  .user-actions {
    width: 100%;
    justify-content: center;
    padding: 15px 0;
    border-top: 1px solid #eee;
  }

  .logo-container {
    flex: 1;
  }
}

/* 动画效果 */
.nav-menu {
  transition: all 0.3s ease-in-out;
}

.mobile-menu-active {
  animation: slideDown 0.3s ease-in-out;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}


.el-main {

padding: 0; /* 移除内边距 */
margin: 0; /* 移除外边距 */
}

/* 主内容区域样式调整 */
.el-main {
  padding: 0;
  margin: 0;
  margin-top: 80px; /* 添加顶部外边距，与导航栏高度相同 */
}

/* 导航菜单样式调整 */
.el-menu-demo {
  height: 80px; /* 确保菜单高度与header一致 */
  line-height: 80px;
  padding: 0 20px; /* 添加水平内边距 */
}

/* Logo容器样式调整 */
.logo-container {
  height: 80px;
  display: flex;
  align-items: center;
  margin-right: 40px; /* 增加与导航项的间距 */
}

/* 导航菜单项样式调整 */
.el-menu-item {
  height: 80px;
  line-height: 80px;
  padding: 0 25px; /* 增加菜单项之间的间距 */
}

/* 响应式调整 */
@media (max-width: 768px) {
  .el-header {
    position: relative; /* 移动端取消固定定位 */
    height: auto;
  }
  
  .el-main {
    margin-top: 0; /* 移动端不需要顶部外边距 */
  }
  
  .el-menu-demo {
    height: auto;
    padding: 0;
  }
  
  .logo-container {
    height: 60px;
    margin-right: 0;
  }
  
  .el-menu-item {
    height: 50px;
    line-height: 50px;
    padding: 0 15px;
  }
}
</style>
