<template>
  <div class="download-container">
    <el-container class="responsive-container">
      <!-- 左侧相机列表 -->
      <el-aside class="responsive-aside">
        <el-card class="camera-list">
          <template #header>
            <div class="card-header">
              <span>产品列表</span>
              <div class="radio-group-wrapper">
                <el-radio-group v-model="currentCameraType" size="small">
                  <el-radio-button label="area">面阵相机</el-radio-button>
                  <el-radio-button label="line">线扫相机</el-radio-button>
                </el-radio-group>
              </div>
            </div>
          </template>
          
          <!-- 相机类型分组 -->
          <el-menu 
            :default-active="activeCamera"
            @select="handleCameraSelect"
          >
            <el-sub-menu v-for="(cameras, type) in currentCameras" :key="type" :index="type">
              <template #title>
                <span>{{ getCameraTypeName(type) }}</span>
              </template>
              <el-menu-item 
                v-for="camera in cameras" 
                :key="camera.id"
                :index="camera.id"
              >
                {{ camera.productModel }}
              </el-menu-item>
            </el-sub-menu>
          </el-menu>
        </el-card>
      </el-aside>

      <!-- 右侧下载区域 -->
      <el-main class="responsive-main">
        <el-card v-if="selectedCamera" class="download-card">
          <template #header>
            <div class="card-header">
              <h2>{{ selectedCamera.productModel }} 产品资料下载</h2>
            </div>
          </template>

          <!-- 产品信息 -->
          <el-descriptions :column="2" border>
            <el-descriptions-item label="产品型号">{{ selectedCamera.productModel }}</el-descriptions-item>
            <el-descriptions-item label="传感器型号">{{ selectedCamera.sensorModel }}</el-descriptions-item>
            <el-descriptions-item label="分辨率">{{ selectedCamera.resolution }}</el-descriptions-item>
            <el-descriptions-item label="最大帧率">{{ selectedCamera.maxFrameRate }}</el-descriptions-item>
            <el-descriptions-item label="接口类型">{{ selectedCamera.dataInterface }}</el-descriptions-item>
            <el-descriptions-item label="色彩">{{ selectedCamera.colorType }}</el-descriptions-item>
          </el-descriptions>

          <!-- 下载按钮区域 -->
          <div class="download-section">
            <h3>可下载文件</h3>
            <div class="download-items">
              <el-card 
                v-for="(label, type) in downloadTypes" 
                :key="type" 
                class="download-item"
                shadow="hover"
              >
                <div class="file-content">
                  <div class="file-info">
                    <el-icon class="file-icon"><Document /></el-icon>
                    <span class="file-name">{{ label }}</span>
                  </div>
                  <el-button 
                    type="primary" 
                    class="download-btn"
                    @click="downloadFile(type)"
                  >
                    <el-icon><Download /></el-icon>
                    下载
                  </el-button>
                </div>
              </el-card>
            </div>
          </div>
        </el-card>

        <el-empty v-else description="请选择要下载的产品"></el-empty>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import { Document, Download } from '@element-plus/icons-vue'
import { cameraDatabase } from '@/utils/cameraData'

export default {
  components: {
    Document,
    Download
  },
  mounted() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  },
  // 如果使用了 setup 语法，可以这样写
  setup() {
    onMounted(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    });
  },
  data() {
    return {
      currentCameraType: 'area',
      activeCamera: '',
      selectedCamera: null,
      downloadTypes: {
        file: '产品规格书',
        software: '配套软件',
        manual: '用户手册',
        drawing: '结构图纸'
      }
    }
  },
  computed: {
    currentCameras() {
      const cameraList = this.currentCameraType === 'area' ? 
        cameraDatabase.areaData : cameraDatabase.lineData;
      
      return cameraList.reduce((acc, camera) => {
        if (!acc[camera.CameraType]) {
          acc[camera.CameraType] = [];
        }
        acc[camera.CameraType].push({
          ...camera,
          id: camera.productModel
        });
        return acc;
      }, {});
    }
  },
  created() {
    // 从 URL 获取相机 ID 和类型
    const { id, type } = this.$route.query
    if (id) {
      // 设置当前相机类型
      this.currentCameraType = type || 'area'
      
      // 加载相机数据
      this.loadCameraById(id)
    }
  },
  methods: {
    getCameraTypeName(type) {
      return type;
    },
    handleCameraSelect(id) {
      const cameraList = this.currentCameraType === 'area' ? 
        cameraDatabase.areaData : cameraDatabase.lineData;
      
      this.selectedCamera = cameraList.find(camera => camera.productModel === id);
      this.activeCamera = id;
      
      // 更新 URL
      this.$router.push({
        query: { id, type: this.currentCameraType }
      });
    },
    loadCameraById(id) {
      // 根据完整产品型号查找相机
      const areaCamera = cameraDatabase.areaData.find(c => c.productModel === id)
      const lineCamera = cameraDatabase.lineData.find(c => c.productModel === id)
      
      if (areaCamera) {
        this.selectedCamera = areaCamera
        this.currentCameraType = 'area'
        this.activeCamera = id
      } else if (lineCamera) {
        this.selectedCamera = lineCamera
        this.currentCameraType = 'line'
        this.activeCamera = id
      }
    },
    downloadFile(fileType) {
      if (!this.selectedCamera) return
      
      // 根据文件类型设置文件名和路径
      let fileName, filePath
      
      switch(fileType) {
        case 'file':
          fileName = `${this.selectedCamera.productModel}技术规格书.pdf`
          filePath = `/files/${fileName}`
          break
        case 'software':
          fileName = `${this.selectedCamera.productModel}_软件包.zip`
          filePath = `/files/software/${fileName}`
          break
        case 'manual':
          fileName = `${this.selectedCamera.productModel}_用户手册.pdf`
          filePath = `/files/manuals/${fileName}`
          break
        case 'drawing':
          fileName = `${this.selectedCamera.productModel}_结构图纸.pdf`
          filePath = `/files/drawings/${fileName}`
          break
        default:
          this.$message.error('未知的文件类型')
          return
      }

      // 发起下载请求
      fetch(filePath)
        .then(response => {
          if (!response.ok) {
            throw new Error('File not found')
          }
          return response.blob()
        })
        .then(blob => {
          const url = window.URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = url
          a.download = fileName
          document.body.appendChild(a)
          a.click()
          a.remove()
          window.URL.revokeObjectURL(url)
          this.$message.success('文件下载成功')
        })
        .catch(error => {
          console.error('Download error:', error)
          this.$message.error(`${this.downloadTypes[fileType]}暂未上传或下载失败，请联系工作人员`)
        })
    }
  }
}
</script>

<style scoped>
/* 基础布局 */
.download-container {
  padding: 20px;
  max-width: 1800px;
  margin: 0 auto;
  overflow-x: hidden; /* 防止水平滚动 */
}

.responsive-container {
  display: flex;
  gap: 30px;
  flex-wrap: wrap; /* 允许在小屏幕上换行 */
}

/* 左侧菜单区域 */
.responsive-aside {
  width: 360px !important;
  transition: all 0.3s;
  min-width: 0; /* 允许内容收缩 */
}

.camera-list {
  height: 100%;
  overflow: hidden; /* 防止内容溢出 */
}

/* 右侧内容区域 */
.responsive-main {
  flex: 1;
  padding: 0;
  min-width: 0; /* 允许内容收缩 */
}

/* 下载卡片样式优化 */
.download-section {
  margin-top: 30px;
  width: 100%;
}

.download-items {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* 自适应列数 */
  gap: 20px;
  width: 100%;
}

.download-item {
  height: 100%;
  min-width: 0; /* 允许卡片收缩 */
}

/* 描述列表样式优化 */
:deep(.el-descriptions) {
  width: 100%;
  overflow-x: auto; /* 允许在小屏幕上滚动 */
}

:deep(.el-descriptions__table) {
  width: 100%;
  min-width: 600px; /* 设置最小宽度确保内容可读 */
}

/* 媒体查询优化 */
@media screen and (max-width: 992px) {
  .download-container {
    padding: 15px;
  }

  .responsive-container {
    gap: 20px;
  }

  .responsive-aside {
    width: 100% !important;
    margin-bottom: 20px;
  }

  /* 优化菜单在平板上的显示 */
  :deep(.el-menu) {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  :deep(.el-sub-menu) {
    width: calc(50% - 5px);
  }

  :deep(.el-menu-item) {
    width: 100%;
  }

  /* 优化描述列表在平板上的显示 */
  :deep(.el-descriptions__table) {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; /* 提供更好的移动端滚动体验 */
  }
}

@media screen and (max-width: 768px) {
  .download-container {
    padding: 10px;
  }

  /* 优化下载项在手机上的显示 */
  .download-items {
    grid-template-columns: 1fr;
    gap: 15px;
  }

  .file-content {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
  }

  /* 优化按钮组在手机上的显示 */
  .card-header {
    flex-direction: column;
    gap: 10px;
  }

  .radio-group-wrapper {
    width: 100%;
  }

  :deep(.el-radio-group) {
    width: 100%;
    display: flex;
  }

  :deep(.el-radio-button) {
    flex: 1;
  }

  :deep(.el-radio-button__inner) {
    width: 100%;
    padding: 8px 12px;
  }

  /* 优化描述列表在手机上的显示 */
  :deep(.el-descriptions__cell) {
    white-space: normal;
    word-break: break-word;
  }
}

@media screen and (max-width: 480px) {
  .download-container {
    padding: 5px;
  }

  /* 优化文件信息在小屏手机上的显示 */
  .file-content {
    flex-direction: column;
    text-align: center;
    gap: 10px;
  }

  .file-info {
    justify-content: center;
  }

  .download-btn {
    width: 100%;
  }

  /* 优化描述列表在小屏手机上的显示 */
  :deep(.el-descriptions__label) {
    width: 80px;
    padding-right: 8px;
    font-size: 13px;
  }

  :deep(.el-descriptions__content) {
    padding-left: 8px;
    font-size: 13px;
  }

  /* 防止内容溢出容器 */
  :deep(.el-descriptions__body) {
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
</style> 