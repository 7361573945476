<!-- src/views/productList/MoreProduct.vue -->
<template>
  <div>
    <el-container>
      <el-main>
        <!-- 顶部图片容器 -->
        <div class="top-banner-wrapper">
          <div class="top-image-container">
            <img src="@/assets/media/boq7.jpg" alt="Banner" class="top-banner-image">
          </div>
        </div>

        <!-- 原有的标题 -->
        <h2 class="page-title">配件</h2>

        <div class="tabs-container">
          <el-tabs class="module-tabs" v-model="activeTab">
            <el-tab-pane class="tab-pane" label="电源适配器" name="power"></el-tab-pane>
            <el-tab-pane class="tab-pane" label="数据线缆" name="data"></el-tab-pane>
            <el-tab-pane class="tab-pane" label="采集卡" name="card"></el-tab-pane>
            <el-tab-pane class="tab-pane" label="工具" name="tools"></el-tab-pane>
          </el-tabs>
        </div>

        <!-- 不同内容区域 -->
        <div v-if="activeTab === 'data'" class="parts-container">
          <el-row :gutter="20">
            <el-col :xs="24" :sm="12" :md="6" v-for="(item, index) in dataParts" :key="index">
              <el-card :body-style="{ padding: '20px' }">
                <img :src="item.image" class="part-image" alt="配件图片" />
                <div class="card-content">
                  <p>{{ item.name }}</p>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </div>

        <div v-if="activeTab === 'card'" class="parts-container">
          <el-row :gutter="20">
            <el-col :xs="24" :sm="12" :md="6" v-for="(item, index) in cardParts" :key="index">
              <el-card :body-style="{ padding: '20px' }">
                <img :src="item.image" class="part-image" alt="配件图片" />
                <div class="card-content">
                  <p>{{ item.name }}</p>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </div>

        <div v-if="activeTab === 'power'" class="parts-container">
          <el-row :gutter="20">
            <el-col :xs="24" :sm="12" :md="6" v-for="(item, index) in powerParts" :key="index">
              <el-card :body-style="{ padding: '20px' }">
                <img :src="item.image" class="part-image" alt="配件图片" />
                <div class="card-content">
                  <p>{{ item.name }}</p>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </div>

        <div v-if="activeTab === 'tools'" class="parts-container">
          <el-row :gutter="20">
            <el-col :xs="24" :sm="12" :md="6" v-for="(item, index) in toolsParts" :key="index">
              <el-card :body-style="{ padding: '20px' }">
                <img :src="item.image" class="part-image" alt="配件图片" />
                <div class="card-content">
                  <p>{{ item.name }}</p>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </div>

        <el-button class="more-button" style="border: none; color: #7aa7f7;" @click="goToProduct">更多 →</el-button>
      </el-main>
    </el-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeTab: 'data',
      dataParts: [
        { name: 'ADS-26FSG-12 12024EPCN', image: require('@/media/cable01.png') },
        { name: 'ADS-26FSG-12 12024EPCN', image: require('@/media/cable01.png') },
      ],
      cardParts: [
        { name: 'BOQ-XGCL02A', image: require('@/media/card01.png') },
        { name: 'BOQ-HZ07C', image: require('@/media/card02.png') },
      ],
      powerParts: [
        { name: 'Adapter12V3A', image: require('@/media/io01.png') },
        { name: 'Adapter24V2A', image: require('@/media/io02.png') },
      ],
      toolsParts: [
        { name: 'BOQ-VIEWER1', image: require('@/media/tool01.png') },
        { name: 'BOQ-VIEWER2', image: require('@/media/tool02.png') },
        { name: 'BOQ-CameraAdapter1', image: require('@/media/tool03.png') },
        { name: 'BOQ-CameraAdapter2', image: require('@/media/tool04.png') },
        { name: 'BOQ-CameraAdapter3', image: require('@/media/tool05.png') },
        { name: 'BOQ-dhaifafhiahfia', image: require('@/media/logo.pic.jpg') },
      ],
    }
  },
  methods: {
    goToProduct() {
      this.$router.push('/Product');
    }
  }
}
</script>

<style scoped>
/* 顶部图片外层容器 */
.top-banner-wrapper {
  width: 100%;
  max-width: 1600px; /* 增加最大宽度以适应图片 */
  margin: 0 auto;
  padding: 0 20px; /* 添加内边距代替百分比宽度 */
}

/* 顶部图片容器样式 */
.top-image-container {
  width: 100%;
  height: 0;
  padding-bottom: 40%; /* 降低高度比例 */
  position: relative;
  overflow: hidden;
  margin-bottom: 40px;
}

.top-banner-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain; /* 改为 contain 以显示完整图片 */
  object-position: center;
}

/* 响应式调整 */
@media (max-width: 1200px) {
  .top-banner-wrapper {
    padding: 0 15px;
  }
  
  .top-image-container {
    padding-bottom: 45%;
  }
}

@media (max-width: 768px) {
  .top-banner-wrapper {
    padding: 0 10px;
  }
  
  .top-image-container {
    padding-bottom: 50%;
    margin-bottom: 30px;
  }
}

@media (max-width: 480px) {
  .top-banner-wrapper {
    padding: 0 5px;
  }
  
  .top-image-container {
    padding-bottom: 55%;
    margin-bottom: 20px;
  }
}

/* 调整标题位置 */
.page-title {
  width: 100%;
  margin: 40px 0;
  text-align: center;
  font-size: 36px;
  font-weight: bold;
  position: relative;
}

/* 响应式调整 */
@media (max-width: 768px) {
  .top-image-container {
    height: 200px;
    margin-bottom: 30px;
  }
  
  .page-title {
    margin: 30px 0;
    font-size: 28px;
  }
}

@media (max-width: 480px) {
  .top-image-container {
    height: 150px;
    margin-bottom: 20px;
  }
  
  .page-title {
    margin: 20px 0;
    font-size: 24px;
  }
}

/* 配件 */
.parts-container {
  margin: 20px 0;
}

.part-image {
  max-width: calc(50% - 10%);
  height: auto;
  object-fit: cover;
  display: block;
  margin: 0 auto;
}

.card-content {
  text-align: center;
  margin-top: 10px;
}

.more-button {
  margin-top: 20px;
  display: block;
  text-align: center;
  width: 100%;
  color: #7aa7f7;
}

/* 卡片默认样式 */
.el-card {
  transition: border-color 0.3s ease, box-shadow 0.3s ease, border-width 0.3s ease;
  border: 1px solid #e4e7ed;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-bottom: 3px solid #409eff;
  margin-bottom: 10px;
}

/* 悬停时的卡片样式 */
.el-card:hover {
  border-color: rgba(64, 158, 255, 0.5);
  border-width: 2px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* 配件选择器样式 */
.module-tabs {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  font-size: 18px;
}

/* 配件选择模块容器样式 */
.tabs-container {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
}

/* 深度选择器>>>覆盖样式 */
.module-tabs>>>.el-tabs__item {
  font-size: 18px;
  padding: 10px 20px;
  text-align: center;
}

/* 悬停状态样式 */
.module-tabs>>>.el-tabs__item:hover {
  color: #409eff;
}

</style>
