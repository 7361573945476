<!-- src/views/Home.vue -->
<template>
  <div class="home-container">
    <el-container>
      <el-main>
        <div class="background-overlay">
          <!-- 走马灯模块 -->
          <div class="carousel-section">
            <div class="carousel-container">
              <el-carousel :interval="5000" arrow="always" @change="handleCarouselChange" height="400px">
                <el-carousel-item v-for="(image, index) in carouselImages" :key="index">
                  <img 
                    :src="image" 
                    alt="Carousel Image" 
                    class="carousel-image"
                    @load="(e) => handleImageLoad(e, index)"
                  />
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>


        
          <!-- 文本显示模块 -->
          <el-row type="flex" justify="center" :gutter="20" class="text-section">
            <el-col :span="12">
              <h3 class="parts-title" style="--after-width: 140px;">产品系列</h3>
              <p class="subtitle">Product Series</p>
            </el-col>
          </el-row>

          <el-row type="flex" justify="center" class="text-description-section">
            <el-col :span="16">
              <p class="text-description">精准捕捉细节，超快输出图像; 自带先进算法，赋能智能制造</p>
            </el-col>
          </el-row>

          <!-- 卡片模块 -->
          <el-row>
            <el-col :span="24">
              <el-row :gutter="10" class="card-section">
                <el-col 
                  v-for="(o, index) in cardImages" 
                  :key="index" 
                  :xs="24" 
                  :sm="24" 
                  :md="8" 
                  :lg="8"
                  class="card-col"
                >
                  <el-card :body-style="{ padding: '0px' }" class="product-card">
                    <img 
                      :src="o.currentSrc" 
                      class="image" 
                      @click="goToProPage" 
                      @mouseenter="handleMouseEnter(o)"
                      @mouseleave="handleMouseLeave(o)" 
                      :class="{ 'image-hover': o.isHovered }" 
                    />
                    <div class="card-content">
                      <div class="card-name">{{ o.name }}</div>
                      <div class="card-description">{{o.p }}</div>
                    </div>
                  </el-card>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </div><!-- background-overlay背景到这里结束 -->
        
        <!-- 宣传模块 -->
        <div class="promotion-section">
          <div class="promotion-container">
            <div class="promotion-content">
              <div class="promotion-text" v-motion
                :initial="{ opacity: 0, x: -100 }"
                :enter="{ opacity: 1, x: 0 }"
                :delay="200"
                :duration="800"
              >
                <h2>科学定制流程，实现快速定制，积木式功能搭建，响应式功能优化。</h2>
                <p>博峤高效的定制化流程，实现最快2小时内交付产品。您可以根据需求随时定制功能，灵活升级，打造专属于您的智能相机解决方案。</p>
                <p>千里之外定位问题，瞬息之间修复上线。</p>
              </div>
              <div class="promotion-image" v-motion
                :initial="{ opacity: 0, scale: 0.8 }"
                :enter="{ opacity: 1, scale: 1 }"
                :delay="500"
                :duration="800"
              >
                <img src="@/media/explode1.jpg" alt="软件界面" />
              </div>
            </div>
          </div>
        </div>
        
        <!-- 优秀案例模块 -->
        <el-row type="flex" justify="center" :gutter="20" class="text-section">
          <el-col :span="12">
            <h3 class="parts-title" style="--after-width: 140px;">优秀案例</h3>
            <p class="subtitle">Excellent Cases</p>
          </el-col>
        </el-row>

        <!-- 新增的容器 -->
        <div class="sample-background">
          <div class="sample-container">
            <div class="sample-grid">
              <div 
                class="sample-item" 
                v-for="(item, index) in SampleList" 
                :key="index"
                @click="gosample(item.id)"
              >
                <div class="sample-image-wrapper">
                  <img :src="item.image" :alt="item.alt" />
                  <div class="sample-overlay">
                    <h4>{{ item.title }}</h4>
                    <p class="sample-description">{{ item.description }}</p>
                    <span class="sample-date">{{ item.date }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 新闻模块 -->
        <el-row type="flex" justify="center" :gutter="20" class="text-section">
          <el-col :span="12">
            <h3 class="parts-title" style="--after-width: 140px;">新闻动态</h3>
            <p class="subtitle">Latest News</p>
          </el-col>
        </el-row>

        <div class="newscontainer">
          <div class="news-images">
            <div 
              class="news-item" 
              @click="navigateToDetail(item.id)" 
              v-for="(item, index) in newsList" 
              :key="index"
            >
              <img :src="item.image" :alt="item.alt" />
              <div class="news-content">
                <p>{{ item.title }}</p>
                <span class="date-stamp">{{ item.date }}</span>
              </div>
            </div>
          </div>
        </div>

        <!-- 您需要获得什么支持，联系我们 -->
        <div class="support">
          <p>您需要获得什么支持？</p>
          <p>我们提供产品选择建议，助您找到合适的解决方案。</p>
          <router-link to="/login">
            <el-button class="custom-button" type="primary" round>联系我们</el-button>
          </router-link>
        </div>




      </el-main>
    </el-container>
  </div>
</template>

<script>
import './Home.css'; 
export default {
  data() {
    return {
       
    newsList: [  //静态文件储存在assert文件夹中
      { id: 1, image: require('@/assets/media/logoFront.jpg'),alt: '项目1', title: '深圳市博峤技术有限公司成立于2022年09月05日', date: '2022.9.5' },
      { id: 2, image: require('@/assets/media/faceSensor.jpg'),alt: '项目2', title: '新征程 新起点---博峤面阵相机上市', date: '2023.5.20' },
      { id: 3, image: require('@/assets/media/linerSensor.png'),alt: '项目4', title: '新拓展 助纺织---博峤线阵相机上市', date: '2024.2.20' },
      { id: 4, image: require('@/assets/media/newsimage6.jpg'),alt: '项目3', title: '心聚力，赢未来', date: '2025.01.18' },
    ],
    SampleList: [
      { 
        id: 1, 
        image: require('@/assets/media/newimage1.jpg'),
        alt: '案例1', 
        title: '冲压模具检测', 
        description: '自带检测算法，达到每分钟20000次检测输出',
        date: '2023.5.17' 
      },
      { 
        id: 2, 
        image: require('@/assets/media/newimage2.jpg'),
        alt: '案例3', 
        title: '振动盘CCD分选系统', 
        description: '4摄像头高速识别与判定，达到4000fps检测控制',
        date: '2024.4.20' 
      },
      { 
        id: 3, 
        image: require('@/media/background3.png'),
        alt: '案例2', 
        title: '器件有无检测', 
        description: '40万像素相机+有无算法适配16000个/min的检测速度',
        date: '2024.11.16' 
      },
      { 
        id: 4, 
        image: require('@/media/background.png'),
        alt: '案例4', 
        title: '标准工业相机', 
        description: '精准捕捉，多分辨率与丰富接口适配不同使用场景',
        date: '2024.1.9' 
      }
    ],

      currentDate: new Date().toLocaleString(),
      // 走马灯图片
      carouselImages: [
        require('@/assets/media/boq.jpg'),
        require('@/assets/media/boq3.jpg'),
        require('@/assets/media/boq5.jpg'),
        require('@/assets/media/boq6.jpg'),
        require('@/assets/media/boq7.jpg'),
      ],
      // 图片卡片的图片数组
      cardImages: [
        {
          // 默认图片 （后续增加新的照片）
          defaultSrc: require('@/assets/camera/facefront6.png'),
          // 鼠标悬浮时的图片
          hoverSrc: require('@/assets/camera/faceback1.png'),
          // 当前显示的图片
          currentSrc: require('@/assets/camera/facefront6.png'),
          isHovered: false,
          name: '基础相机系列', // 卡片名称在这里更改
          p:'多种分辨率，多种接口，彩色黑白可选'
        },
        {
          defaultSrc: require('@/assets/camera/minifront1.png'),
          hoverSrc: require('@/assets/camera/minifront2.png'),
          currentSrc: require('@/assets/camera/minifront1.png'),
          isHovered: false,
          name: '功能相机系列',
          p:'相机自带算法，直驱控制'
        },
        {
          defaultSrc: require('@/assets/camera/8kback1.png'),
          hoverSrc: require('@/assets/camera/8kback2.png'),
          currentSrc: require('@/assets/camera/8kback1.png'),
          isHovered: false,
          name: 'AI相机系列',
          p: 'AI加持，超凡表现'
        }
      ]
      ,
    };
  },
  computed: {
    carouselStyle() {
      return {
        '--carousel-bg-left': this.currentBgColor.left,
        '--carousel-bg-right': this.currentBgColor.right
      }
    }
  },
  methods: {
    navigateToDetail(newsId) {
    this.$router.push(`/news_detail/${newsId}`);
    },
    gosample(sampleID) {
      this.$router.push(`/sample/${sampleID}`);
    },
    goToProPage() {
      this.$router.push('/Product');
    },
    handleMouseEnter(image) {
      image.currentSrc = image.hoverSrc;
      image.isHovered = true;
    },
    handleMouseLeave(image) {
      image.currentSrc = image.defaultSrc;
      image.isHovered = false;
    },
    observePromotionSection() {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible');
          }
        });
      }, {
        threshold: 0.5 // 当元素50%进入视口时触发
      });

      // 观察宣传模块的文字和图片元素
      const promotionText = document.querySelector('.promotion-text');
      const promotionImage = document.querySelector('.promotion-image');
      
      if (promotionText) observer.observe(promotionText);
      if (promotionImage) observer.observe(promotionImage);
    }
  },
  mounted() {
    this.observePromotionSection();
  }
}
</script>

<style scoped>



</style>
