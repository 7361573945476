<!-- src/views/Subscribe.vue -->
<!-- 订阅功能最终目标要实现点击订阅跳转到支付界面用户付款后返回收款id -->
<template>
  <div class="subscribe-container">
    <h1 class="page-title" style="--after-width: 240px;">暂未开放</h1>
    <p class="subtitle">Coming Soon</p>
    <p class="waiting-text">尽情期待</p>
  </div>
</template>
<style scoped>
.subscribe-container {
  text-align: center;
  padding: 40px 20px;
}

/* 标题样式 */
.page-title {
    width: 100%;
    margin-bottom: 40px;
    text-align: center;
    position: relative; /* 相对定位用于伪元素定位 */
    padding-left: 0px; /* 可根据需要调整 padding */
}

/* 伪元素 平行四边形 */
.page-title::after {
  content: '';
  display: block;
  width: var(--after-width); /* 使用自定义属性来动态设置宽度 */
  height: 6px; /* 高度 */
  background-color: #409eff;
  position: absolute;
  left: 50%;
  transform: translateX(-50%) skewX(-20deg); /* 水平居中旋转成平行四边形 */
  bottom: -1px; /* 控制横线到标题的距离 */
}

/* 副标题样式 */
.subtitle {
  text-align: center;
  color: #666;
  font-size: 14px;
  margin-top: -15px;
  margin-bottom: 25px;
  font-family: 'Arial', sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
}

/* 等待文本样式 */
.waiting-text {
  font-size: 18px;
  color: #409eff;
  margin-top: 20px;
}

.card-sub {
  display: flex;
  flex-wrap: wrap; /* 保证在大屏幕下仍是水平排列 */
  justify-content: space-between;
}

.card-sub .el-col {
  display: flex;
  flex-direction: column; /* 使卡片在el-col中垂直排列 */
  align-items: center; /* 使内容在卡片中居中 */
  flex: 1 1 30%; /* 默认情况下每个卡片占据30%的宽度 */
  max-width: 30%;
  box-sizing: border-box; /* 确保padding和border不会影响宽度 */
  margin-bottom: 20px; /* 添加卡片之间的底部间距 */
}

.card-sub .el-card {
  display: flex;
  flex-direction: column; /* 使卡片内容垂直排列 */
  justify-content: space-between; /* 保证内容之间的均匀间距 */
  width: 100%; /* 确保卡片占满列宽度 */
  height: 100%; /* 确保卡片占满列高度 */
}

/* 小屏幕时，卡片垂直排列 */
@media (max-width: 699px) {
  .card-sub {
    flex-direction: column; /* 小屏幕时卡片垂直排列 */
    align-items: center; /* 卡片居中对齐 */
  }

  .card-sub .el-col {
    flex: 1 1 auto; /* 小屏幕时每个卡片占据自动高度 */
    max-width: 100%; /* 强制最大宽度为100% */
    margin-bottom: 20px; /* 保持卡片之间的底部间距 */
  }
  
  .card-sub .el-card {
    width: 100%; /* 小屏幕时卡片占满父容器宽度 */
  }
}

/* 响应式调整 */
@media screen and (max-width: 768px) {
  .page-title {
    font-size: 24px;
  }
  
  .subtitle {
    font-size: 12px;
  }
  
  .waiting-text {
    font-size: 16px;
  }
}
</style>