import { createRouter, createWebHistory } from 'vue-router'

import Home from '../views/Home.vue'
import Prodcut from '../views/Product.vue'
import Subscribe from '../views/Subscribe.vue'
import Login from '../views/Login.vue';
import User from '../views/User.vue' ; 

import MoreProduct from '../views/productList/MoreProduct.vue';

import NewsDetail from '../views/NewsDetail.vue';
import SampleDetail from '../views/SampleDetail.vue';
import News_Detail from '../views/NewsDetail.vue';
import Download from '../views/Download.vue';
// import Spport from '../views/Spport.vue';

import TechSupport from '../views/TechSupport.vue';

const routes = [
  {
    path: '/',
    redirect: '/Home' 
  },
// 默认重定向到 /Home
  {
    path: '/sample/:id',
    name: 'SampleDetail',
    component: SampleDetail
  },
  {
  path: '/news_detail/:id',
  name: 'News_Detail',
  component: News_Detail
  },
  {
    path: '/Home',
    name: 'Home',
    component: Home
  },
  {
    path: '/Product',
    name: 'Product',
    component: Prodcut
  },

  {
    path: '/Subscribe',
    name: 'Subscribe',
    component: Subscribe
  }
  ,
  {
    path: '/Login',
    name: 'Login',
    component: Login
  }
  ,
  {
    path: '/User',
    name: 'User',
    component: User
  }
  ,
  
  {
    path: '/MoreProduct',
    name: 'MoreProduct',
    component: MoreProduct
  }
  // 产品细分结束
  ,

  {
    path: '/NewsDetail',
    name: 'NewsDetail',
    component: NewsDetail
  }
  ,
  {
    path: '/Download',
    name: 'Download',
    component: Download
  }
  ,
  {
    path: '/TechSupport',
    name: 'TechSupport',
    component: () => import('@/views/TechSupport.vue')
  }
]



const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return {
      top: 0,
      behavior: 'smooth'
    }
  }
})

export default router

// 添加全局导航守卫
router.beforeEach((to, from, next) => {
  console.log('Navigating from', from.path, 'to', to.path);
  if (document.documentElement.scrollTop || document.body.scrollTop) {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }
  next();
})