<template>
    <div class="news-detail-container">
        <el-row class="row-bg" :gutter="20">
            <!-- 在小屏幕下显示的 sidebar -->
            <el-col :xs="24" :sm="24" :md="0" class="mobile-sidebar">
                <div class="sidebar-content">
                    <h3 class="carousel-title">{{ currentCarouselTitle }}</h3>
                    <el-carousel 
                        height="200px" 
                        direction="vertical" 
                        ref="carousel"
                        @change="handleCarouselChange">
                        <el-carousel-item v-for="(item, index) in news_list" :key="item.id">
                            <img :src="item.image" alt="News Image" class="sidebar-img" @click="goToNewsDetail(item.id)" />
                        </el-carousel-item>
                    </el-carousel>
                </div>
            </el-col>

            <!-- 主内容区 -->
            <el-col :xs="24" :sm="24" :md="18" class="content-center">
                <div class="grid-content ep-bg-purple"></div>
                <img :src="news.image" alt="News Image" class="news-image" />
                <h1 class="news-title">{{ news.title }}</h1>
                <p class="news-content">{{ news.content }}</p>
            </el-col>

            <!-- 在大屏幕下显示的 sidebar -->
            <el-col :xs="0" :sm="0" :md="6" class="desktop-sidebar">
                <div class="sidebar-content">
                    <h3 class="carousel-title">{{ currentCarouselTitle }}</h3>
                    <el-carousel 
                        height="200px" 
                        direction="vertical" 
                        ref="carousel"
                        @change="handleCarouselChange">
                        <el-carousel-item v-for="(item, index) in news_list" :key="item.id">
                            <img :src="item.image" alt="News Image" class="sidebar-img" @click="goToNewsDetail(item.id)" />
                        </el-carousel-item>
                    </el-carousel>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
  data() {
    return {
      news: {},
      currentCarouselTitle: '', // 当前走马灯标题
      news_list:[
      { id: 1, image: require('@/assets/media/logoFront.jpg'),alt: '项目1', title: '深圳市博峤技术有限公司成立于2022年09月05日', date: '2022.9.5' },
      { id: 2, image: require('@/assets/media/faceSensor.jpg'),alt: '项目2', title: '新征程 新起点---博峤面阵相机上市', date: '2023.5.20' },
      { id: 3, image: require('@/assets/media/linerSensor.png'),alt: '项目4', title: '新拓展 助纺织---博峤线阵相机上市', date: '2024.2.20' },
      { id: 4, image: require('@/assets/media/newsimage6.jpg'),alt: '项目3', title: '心聚力，赢未来', date: '2025.01.18' },

        ],
    };
  },
  created() {
    const newsId = this.$route.params.id;
    this.loadNewsDetail(newsId);
    this.currentCarouselTitle = this.news_list[0].title; // 初始化标题
  },
  methods: {    
    loadNewsDetail(id) {
      const newsData = [
          { id: 1, title: '深圳市博峤技术有限公司成立于2022年09月05日', image: require('@/assets/media/logoFront.jpg'), 
          content: `
          深圳市博峤技术有限公司是一家充满活力的高新技术企业，于2022年9月5日在创新之城深圳正式成立。公司汇聚了一批在人工智能，机器视觉等领域拥有深厚积淀的行业精英，
          专注于在机器视觉，物体识别领域的技术创新。博峤科技秉承专业定制，客户至上的核心理念，以持续创新、追求卓越为行动指南。\r  
          我们始终坚持以客户为中心，以技术为驱动，为客户提供最优质的产品和服务。我们的核心竞争力在于雄厚的技术实力、丰富的行业经验和强大的创新能力。
          公司拥有一支由行业资深专家、优秀工程师组成的研发团队，具备算法研发、数据分析、系统集成等能力，能够为客户提供客制化的解决方案。博峤科技的产品和服务主要包括相机，
          功能相机，以及AI识别相机等产以及配套服务，如定制硬件、软件开发、云服务等。我们的产品和服务具有高性能、高可靠性、易用性、可定制性等特点，
          能够满足客户在不同场景下的多样化需求。博峤科技的愿景是成为全球知名的解决方案提供商。我们将持续加大研发投入，不断拓展业务领域，为客户创造更大的价值，
          为社会的发展贡献力量。随着自动化产业的不断深入，博峤科技将抓住机遇，迎接挑战。我们将继续深耕机器视觉领域，不断提升技术水平，拓展市场规模。
          同时，我们将积极探索新的业务增长点，为公司未来的发展注入新的活力。深圳市博峤技术有限公司的成立，标志着我们开启了崭新的一页。我们将以饱满的热情、创新的精神、
          专业的服务，为客户创造价值，为社会的发展贡献力量。让我们携手共进，共同开创美好的未来！` },

          { id: 2, title: '新征程 新起点---博峤面阵相机上市', image: require('@/assets/media/faceSensor.jpg'), 
          content:`
          博峤自研面阵相机盛大上市，开启智能视觉新纪元！2023年5日20，博峤科技隆重发布全新自研面阵相机，标志着我们在智能视觉领域迈出了坚实的一步。这款相机搭载了索尼IMX系列传感器的面阵相机，
          这几款相机在分辨率、帧率、灵敏度等方面实现了稳定发展。
            ` },
          
          { id: 4, title: '心聚力，赢未来',image: require('@/assets/media/newsimage6.jpg'), 
          content: 
          `博峤全体员工踊跃参与了母公司珠海博杰举办的2025年年会，现场气氛热烈，大家深切感受到了博杰全体同仁的热情欢迎与盛情招待。
          这一盛会极大鼓舞了博峤技术团队，让我们更加坚定信心，勇敢面对挑战，不断前行。在这辞旧迎新的时刻，博峤技术全体员工向大家致以诚挚的节日问候，
          衷心祝愿大家在新的一年里事业蒸蒸日上，生活更加美好！` },

          { id: 3, title: '新拓展 助纺织---博峤线阵相机上市', image: require('@/assets/media/linerSensor.png'), 
          content: 
          `博峤自研线扫相机的研发取得了成功，标志着博峤相机系列在技术和产品应用上的又一次突破。此次推出的线阵相机不仅在成像精度和处理速度上实现了大幅提升，
          还具备了适应纺织行业高效作业需求的强大功能。这款相机的成功上市，意味着博峤在相机领域的拓展进入了一个新的阶段，进一步丰富了博峤的产品线，
          并为各大行业特别是纺织行业提供了更为精确和高效的视觉检测解决方案。博峤相机系列通过不断创新与技术升级，逐步覆盖了从工业检测到智能制造的多种场景。
          新款线扫相机凭借其高分辨率、稳定性和高速采集能力，不仅可以满足纺织行业复杂的生产检测需求，还为其他行业的精细化检测提供了更广泛的应用场景。
          此次新产品的推出，进一步奠定了博峤在工业视觉领域的领先地位，助力不同产业在自动化和智能化方向上的发展。` },
        ];
      this.news = newsData.find(item => item.id === parseInt(id));
    },
    goToNewsDetail(id) {
      this.$router.push({ path: `/news_detail/${id}` });
    },
    handleCarouselChange(index) {
      this.currentCarouselTitle = this.news_list[index].title; // 更新标题
    },
  }
};
</script>

<style scoped>
.news-detail-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

/* 桌面端侧边栏样式 */
.desktop-sidebar {
    padding: 0 15px;
}

/* 移动端侧边栏样式 */
.mobile-sidebar {
    margin-bottom: 20px;
}

.sidebar-content {
    background-color: #f8f9fa;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.carousel-title {
    text-align: center;
    font-size: 1.2rem;
    margin-bottom: 15px;
    color: #333;
    padding: 10px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.sidebar-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.sidebar-img:hover {
    transform: scale(1.02);
}

/* 响应式布局调整 */
@media (max-width: 768px) {
    .news-detail-container {
        padding: 10px;
    }

    .mobile-sidebar {
        margin-bottom: 15px;
    }

    .carousel-title {
        font-size: 1rem;
        margin-bottom: 10px;
    }

    .news-title {
        font-size: 1.3rem;
    }

    .news-content {
        font-size: 1rem;
        line-height: 1.6;
    }
}

/* 保留原有的其他样式 */
.content-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.news-image {
    max-width: 100%;
    height: auto;
    margin-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.news-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 15px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

.news-content {
    font-size: 1.1rem;
    line-height: 1.8;
    color: #555;
    text-align: justify;
    margin-bottom: 20px;
    padding: 15px;
    background-color: #fff;
    border-left: 4px solid #409eff;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}
</style>